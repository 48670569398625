// TODO: fix activeLanguage = i18n.language ts warning

import { css, cx } from '@linaria/core';
import { styled } from '@linaria/react';
import { mdiMagnify } from '@mdi/js';
import Icon from '@mdi/react';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import { sendIt } from 'gatsby-plugin-purina-analytics/common/functions';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import * as React from 'react';
import { Dispatch, SetStateAction } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { defaultColors } from 'src/theme/colors';
import { theme } from '../../theme/theme';
import SearchPageService, { PageTypeLabels } from '../search_listing/SearchPageService';

const StyledForm = styled(Form)`
  width: 0;
  overflow: hidden;
  flex-direction: column;
  transition: width 0.3s ease;
  &.show {
    width: 100%;
  }
  @media (min-width: ${theme.media.lg}) {
    width: 100%;
  }
  @media screen and (max-width: ${theme.media.sm}) {
    font-size: 0.875rem;
  }
  .search-wrapper {
    height: 48px; // This hardcodes the Search component's height. Drop if you want a slimmer profile.
  }
  .form-control {
    color: ${theme.common.gray[600]};
    outline: none;
    border: none;
    box-shadow: none;
    padding-left: 25px;
    margin-bottom: 0;
    background-color: inherit;
    &::placeholder {
      color: ${theme.common.gray[600]};
    }
    &:focus::placeholder {
      color: transparent;
    }
  }

  button,
  button:hover {
    color: #e91c24 !important;
  }

  button:focus-visible {
    outline: auto;
  }
`;

interface ParagraphSearch extends Paragraph {
  relationships: {
    field_search_page: NodeContent;
  };
}

interface SearchFormProps {
  node?: ParagraphSearch;
  id?: string;
  props: {
    showCancel: boolean;
    showSearch: boolean;
    showMenu: boolean;
    setShowSearch: Dispatch<SetStateAction<boolean>>;
    searchRef: React.RefObject<any>;
    selectedSearchCategory?: 'events' | 'centresquare' | 'resources' | 'articles' | 'pages';
    is_listing_page?: boolean;
  };
}

const SearchFilter = styled.select`
  background: ${defaultColors.subNavBg};
  border: none;
  padding: 10px 35px 10px 20px;

  background-position: right 5rem center;

  /* remove down arrow default */
  appearance: none;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 16px 12px;
  background-position: right 0.75rem center;
`;

const classButtonSearch = css`
  padding: 0;
  padding-right: 15px;
`;

const ParagraphSearchForm: React.FC<SearchFormProps> = function ({ id = 0, node, props }) {
  const { t, i18n } = useTranslation();
  // @ts-ignore
  const activeLanguage = i18n.language;
  const { showSearch, setShowSearch, showCancel = true, selectedSearchCategory } = props;
  const location = useLocation();
  const classes = node?.behavior_settings?.decoupled_styles?.classes?.join(' ') || '';
  const showSearchRef = React.useRef<HTMLButtonElement | null>(null);
  const queryParams = Object.fromEntries(new URLSearchParams(location.search));
  const [term, setTerm] = React.useState(queryParams.keywords ?? '');
  const searchPages = SearchPageService.useGetSearchPages(activeLanguage);
  const activePage = SearchPageService.useGetActiveSearchPage(activeLanguage).api_slug;
  const [index, setIndex] = React.useState(activePage);
  const is_listing_page = props?.is_listing_page ?? false;
  const targetSearchPage =
    selectedSearchCategory &&
    searchPages.find(page => page.node.api_slug === selectedSearchCategory);

  const options = (targetSearchPage ? [targetSearchPage] : searchPages).map(page => {
    // TODO: when in the gatsby-config file we have every page translated, we need to remove this piece and just leave the originalTranslatedLabel
    /* i18next-extract-mark-context-next-line ["Resources", "Articles", "Pages"] */
    const originalTranslatedLabel = t('PageTypeLabels', {
      context: PageTypeLabels[page.node.api_slug],
    });
    const label =
      originalTranslatedLabel === 'PageTypeLabels'
        ? PageTypeLabels[page.node.api_slug]
        : originalTranslatedLabel;

    return (
      <option value={page.node.api_slug} key={page.node.id}>
        {label}
      </option>
    );
  });

  React.useEffect(() => {
    const closeSearch = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        setShowSearch(false);
        showSearchRef?.current?.focus();
      }
    };
    if (showSearch) {
      window.addEventListener('keydown', closeSearch);
    }
    return () => window.removeEventListener('keydown', closeSearch);
  }, [showSearch, setShowSearch]);

  React.useEffect(() => {
    setTerm(queryParams.keywords ?? '');
  }, [queryParams.keywords]);

  const onSearchFocus = () => {
    const newPath = searchPages.filter(page => {
      return page.node.api_slug === index;
    })[0].node.path.alias;
    const analyticsData = {
      event: 'search_initiate',
      eventCategory: 'search_initiate',
      eventAction: newPath,
      eventLabel: node?.type ?? '',
      eventParams: {
        default_search_category: newPath,
        page_type: node?.type ?? '',
      },
    };
    sendIt(analyticsData);
  };
  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (location) {
      if (is_listing_page) {
        navigate(`?keywords=${term}`, { state: { didSearch: true } });
      } else {
        const newPage = searchPages.find(page => page.node.api_slug === index)
          ?.node as (typeof searchPages)[0]['node'];
        
        // we are still going to statically look for the keywords query param right?
        if (newPage.api_slug === 'events') {
          navigate(`${newPage.path.alias}?keywords=${term}&sort_by=latest`, { state: { didSearch: true } });
        } else {
          navigate(`${newPage.path.alias}?keywords=${term}`, { state: { didSearch: true } });
        };
        
      }
    }
  };

  const updateIndex = (activeIndex: string) => {
    setIndex(activeIndex);
  };

  const setFocus = (event: React.MouseEvent<HTMLInputElement>) => {
    event.currentTarget.setSelectionRange(0, event.currentTarget.value.length);
  };

  return (
    <div className={cx(classes, 'd-flex search justify-content-end')}>
      <StyledForm
        action={index}
        noValidate
        onSubmit={onSubmit}
        className={cx('d-lg-flex', props?.showSearch ? 'd-flex show' : 'd-none')}
      >
        <Form.Group className="d-flex bg-light rounded w-100 search-wrapper">
          <SearchFilter
            className="fw-light rounded"
            as="select"
            onChange={e => {
              updateIndex(e.target.value);
            }}
            value={index}
            aria-label={t('Search Categories') || ''}
            ref={props.searchRef}
          >
            {options}
          </SearchFilter>
          <Form.Control
            type="text"
            name="keywords"
            onFocus={onSearchFocus}
            placeholder={t('Search') || ''}
            onChange={e => setTerm(e.target.value ?? '')}
            onClick={setFocus}
            value={term ?? ''}
            aria-label={t('Search site') || ''}
            className="fw-light"
          />
          <Button
            className={classButtonSearch}
            type="submit"
            variant="link"
            aria-label={t('Site search submit') || ''}
          >
            <Icon path={mdiMagnify} size={1} color="inherit" aria-hidden />
          </Button>
        </Form.Group>
        {showCancel && (
          <Button
            variant="btn-link"
            aria-label={t('Cancel search') || ''}
            id={`search-toggle-close-${node?.id || id}`}
            className={cx(
              'text-decoration-none',
              'p-1',
              props?.showSearch ? 'd-block d-lg-none' : 'd-none',
            )}
            onClick={() => props?.setShowSearch(false)}
          >
            {t('Cancel')}
          </Button>
        )}
      </StyledForm>
      <Button
        variant="link"
        aria-label={t('Show search') || ''}
        id={`search-toggle-${node?.id || id}`}
        className={cx(
          'rounded-pill p-2 d-lg-none ms-2 text-decoration-none justify-content-center order-2',
          props?.showSearch || props?.showMenu ? 'd-none' : 'd-inline-flex',
        )}
        onClick={() => {
          props?.setShowSearch(true);
          setTimeout(() => props.searchRef.current.focus(), 50);
        }}
        ref={showSearchRef}
        style={{
          height: '48px',
          width: '48px',
          backgroundColor: defaultColors.subNavBg,
        }}
      >
        <Icon path={mdiMagnify} size={1} color="inherit" aria-hidden />
      </Button>
    </div>
  );
};

export default ParagraphSearchForm;
